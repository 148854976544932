<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 11:49:21
 * @LastEditTime: 2023-04-11 17:36:16
 * @Descripttion: 【互动中心】消息
-->
<style lang="scss" scoped>
.message {
  @include innerPage;
  @include pageHead(-42px);

  .page-inner {
    width: 100%;
    height:96%;
    margin-top: 36px;
    @include flexBox(space-between, flex-start);

    .im {
      &-wrapper {
        flex-grow: 1;
        height: 100%;
        border-radius: 10px;
        background: #edeff6;
        overflow: hidden;
        @include flexBox;
      }

      &-contacts {
        width: 304px;
        height: 100%;
        background: #f0f3fa;
        .notUser{
            text-align: center;
            line-height: 80px;
            color: #999999;
          }
        .contacts {
          &-type {
            width: 274px;
            height: 84px;
            margin: 0 auto;
            box-sizing: border-box;
            border-bottom: 1px solid rgba($color: #8b8b8b, $alpha: 0.24);
            position: relative;
            @include flexBox;

            li {
              width: 50%;
              text-align: center;
              font-size: 16px;
              color: #2a2a2a;
              cursor: pointer;
              line-height: 84px;

              &.current {
                color: #6340c8;
                font-weight: bold;
              }
            }

            // &::after {
            //   content: "";
            //   width: 38px;
            //   height: 5px;
            //   border-radius: 2.5px;
            //   background: #58419c;
            //   position: absolute;
            //   bottom: 0;
            //   left: 0;
            //   transform: translateX(27px);
            // }

            &.c-2 {
              &::after {
                transform: translateX(120px);
              }
            }
            &.c-3 {
              &::after {
                transform: translateX(210px);
              }
            }
          }
          &-wrapper {
            width: 294px;
            height: calc(100% - 140px);
            margin: 38px auto 10px;
            overflow: hidden;
            overflow-y: auto;
          }

          &-list {
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;

            li {
              width: 100%;
              height: 68px;
              border-radius: 10px;
              margin-top: 28px;
              padding: 0 30px 0 18px;
              box-sizing: border-box;
              cursor: pointer;
              @include flexBox;

              &:first-child {
                margin-top: 0;
              }

              &.chatting {
                background: rgba($color: #c6c6c5, $alpha: 0.5);
              }

              .el-badge {
                @include flexBox;

                ::v-deep {
                  .el-badge__content {
                    top: 8px;
                    height: 18px;
                    line-height: 18px;
                    background-color: #f66478;
                  }
                }
              }

              .el-avatar {
                border: 1px solid #ececee;
                border-radius: 50%;
              }

              .name {
                font-size: 16px;
                color: #2b2b2b;
                margin: 0 auto 0 10px;
                flex: 1;
              }

              .status {
                i {
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: #2ac293;

                  &.offline {
                    background: #c2c4c8;
                  }
                }

                span {
                  color: #7a7a7a;
                  margin-left: 6px;
                }
              }
            }
          }
        }
      }

      &-chatting {
        width: calc(100% - 304px);
        height: 100%;
        box-sizing: border-box;
        padding: 6px 30px 30px;

        .message {
          &-reply {
            height: calc(100% - 228px);

            h3 {
              font-size: 22px;
              line-height: 78px;
              color: #303030;
            }

            .replay {
              &-box {
                height: calc(100% - 78px);
                border: solid rgba($color: #d7d7d7, $alpha: 0.5);
                border-width: 1px 0;
                padding: 30px 0;
                box-sizing: border-box;
                overflow: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                  width: 0 !important;
                }

                .date {
                  text-align: center;
                  line-height: 24px;
                  margin-bottom: 20px;
                  color: #9d9d9d;
                }
              }

              &-item {
                margin-bottom: 20px;
                @include flexBox;
                align-items: flex-start;

                &.mine {
                  justify-content: flex-end;

                  .replay-text {
                    color: #fff;
                    background: #2ac293;
                    display: flex;
                    flex-wrap: wrap;

                    &::before {
                      content: "";
                      border-right-color: transparent;
                      border-left-color: #2ac293;
                      left: unset;
                      right: -16px;
                      top: 16px;
                    }
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }

              &-text {
                max-width: 60%;
                min-height: 50px;
                background: #fff;
                border-radius: 10px;
                line-height: 26px;
                box-sizing: border-box;
                padding: 12px 20px;
                color: #303030;
                margin: 0 20px;
                position: relative;
                display: flex;
                flex-wrap: wrap;

                .text {
                  text-align: justify;
                }

                .text-img {
                  width: 26px;
                  height: 26px;
                }

                &::before {
                  content: "";
                  border: 10px solid transparent;
                  border-right-color: #fff;
                  position: absolute;
                  left: -16px;
                  top: 16px;
                }
              }
            }
            .selectMore{
              text-align: center;
              line-height: 80px;
              color: #999999;
            }
          }

          &-write {
            width: 100%;
            height: 228px;
            box-sizing: border-box;
            padding-top: 24px;
            overflow: auto;

            .write-tool {
              width: 100%;
              height: 30px;
              @include flexBox;

              button {
                padding: 0;
                border: none;
                outline: none;
                font-size: 30px;
                color: #9d9d9d;
                margin-left: -3px;

                &:last-child {
                  margin-left: 17px;
                }
              }
            }

            .ipt-box {
              height: calc(100% - 100px);
              margin: 14px 0;

              textarea {
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                outline: none;
                font-size: 14px;
                color: #303030;
                caret-color: #6340c8;
                resize: none;

                &::-webkit-scrollbar {
                  width: 0 !important;
                }
              }
            }

            .el-button {
              margin-left: calc(100% - 102px);
            }
          }
        }
      }
    }
  }
}

</style>

<template>
  <section class="message">
    <div class="page-inner">
      <!-- im聊天面板 -->
      <div class="im-wrapper">
        <div class="im-contacts">
          <ul class="contacts-type">
            <li class="current">聊天中心</li>
          </ul>
          <div class="contacts-wrapper">
            <ul class="contacts-list">
              <!-- 正在聊天的消息模块 -->
              <li :class="item.userProfile.userID==userProfile.userID?'chatting':''" @click="setUserID(item.userProfile)" v-for="(item,index) in chatList" :key="index">
                <el-badge :value="item.unreadCount" :max="99" :hidden="item.unreadCount<1">
                  <el-avatar fit="cover" :size="62" :src="formatfile(item.userProfile.avatar)" class="avatar">
                    <img src="@assets/images/empty_avatar.png" />
                  </el-avatar>
                </el-badge>
                <p class="name">{{item.userProfile.nick}}</p>
                <p class="status">

                  {{ item.lastMessage.lastTime * 1000 | formatTime('MM-DD') }}
                </p>
              </li>
              <div class="notUser" v-if="chatList.length<1">~暂无聊天信息~</div>
            </ul>
          </div>
        </div>
        <div class="im-chatting">
          <div class="message-reply">
            <h3 class="bold">{{userProfile.nick || '-'}}</h3>
            <div class="replay-box" ref="replayBox">
              <div ref="replayBoxHeight">
                <div class="selectMore" v-if="!isCompleted">加载更多消息</div>
                <div v-for="(item, index) in messageList" :key="index">
                  <!-- 消息时间 -->
                  <p class="date">{{ item.time * 1000 | caculateTimeago }}</p>
                  <div class="replay-item" :class="item.from != userProfile.userID ? 'mine' : ''">
                    <!-- 对方的头像 -->
                    <el-avatar class="avatar" v-if="item.from == userProfile.userID" fit="cover" :size="52" :src="formatfile(item.avatar)">
                      <img src="@assets/images/empty_avatar.png" />
                    </el-avatar>
                    <!-- 文本 / 文本 + 图片 消息内容 -->
                    <div class="replay-text" v-if="item.type == 'TIMTextElem'">
                      <div v-for="(textitem, textindex) in setDecodeText(item.payload.text)" :key="textindex">
                        <p class="text" v-if="textitem.name === 'text'" v-html="textitem.text" />
                        <img class="text-img" v-else-if="textitem.name === 'img'" :src="textitem.src" />
                      </div>
                    </div>
                    <!-- 图片消息 -->
                    <div class="replay-text" v-if="item.type == 'TIMImageElem'">
                      <el-image style="width: 100px; height: 100px; float: left" :src="item.payload.imageInfoArray[1].imageUrl" fit="cover" :preview-src-list="[formatfile(item.payload.imageInfoArray[0].imageUrl)]"/>
                    </div>
                    <!-- 自己的头像 -->
                    <el-avatar class="avatar" v-if="item.from != userProfile.userID" fit="cover" :size="52" :src="formatfile(userInfo.stuser_image)">
                      <img src="@assets/images/empty_avatar.png" />
                    </el-avatar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- 聊天输入模块 -->
            <message-input @submitInput="submitInput" @upImg="upImg"></message-input>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { formatFile} from "@utils";
import { mapState } from "vuex"
import { decodeText } from "./chat/components/decodeText";
import messageInput from "./chat/components/message-input";
export default {
  name: "interaction_message",
  components: {
    messageInput
  },
  computed: {
    ...mapState('user', ['userInfo']),
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  data() {
    return {
      //聊天的对象
      userProfile: {
        userID: "",
        avatar:"",
        nick:"",
      },
      //聊天信息列表
      messageList: [],
      //当前聊天窗口的高度
      boxHeight:"",
      //分页续拉需要传入的字段
      nextReqMessageID: "",
      // 表示是否已经拉完所有消息。
      isCompleted: true,
      //聊天记录
      chatList: [],
    };
  },
  created() {
    if(this.$route.params.userProfile){
      this.userProfile=this.$route.params.userProfile
    }
    setTimeout(() => {
      this.getConversationList()
    }, 1000);
  },
  mounted() {
    this.$refs.replayBox.addEventListener('scroll', this.replayBoxScroll, true)
  },
  methods: {
    /** 即时通讯获取当前聊天消息 */
    getMESSAGE_RECEIVED() {
      this.tim.on(this.TIM.EVENT.MESSAGE_RECEIVED, (event) => {
        if(event.data[0].from==this.userProfile.userID){
          this.messageList.push(event.data[0]);
          //消息设为已读
          // this.massageRead();
          //滚动到最新消息
          this.replayBoxScrollBottom()
        }
      }, this);
    },
    // 取消通讯监听
    deleteMESSAGE_RECEIVED(){
      let onMessageReceived = function(event) {};
      this.tim.off(this.TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
    },
    /** 消息设为已读 */
    massageRead() {
      console.log()
      this.tim.setMessageRead({
        conversationID: "C2C" + this.userProfile.userID,
      }).then((imResponse) => {
        // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
      }).catch(function (imError) {
        // 已读上报失败
        console.warn("已读上报失败-------------------", imError);
      });
    },
    /** 发送消息和表情 */
    submitInput(text) {
      if(text.length<1){
        this.$message({
          message: '请输入聊天内容',
          type: 'warning'
        });
        return false
      }
      let message = this.tim.createTextMessage({
        to: this.userProfile.userID,
        conversationType: "C2C",
        payload: { text },
      });
      this.tim.sendMessage(message);
      this.messageList.push(message);
      setTimeout(() => {
        this.replayBoxScrollBottom();
      }, 20);
      //消息设为已读
      this.massageRead();
    },
    /** 上传图片 */
    upImg({ res }) {
      let message = this.tim.createImageMessage({
        to: this.userProfile.userID,
        conversationType: "C2C",
        payload: {
          file: res,
        },
        needReadReceipt: true,
      });
      this.tim.sendMessage(message);
      this.messageList.push(message);
      setTimeout(() => {
        this.replayBoxScrollBottom();
      }, 20);
    },
    /** 文字图片转译 */
    setDecodeText(text) {
      text = text.replace(/\n/g, '<br/>');
      return decodeText(text);
    },
    /** 获取聊天历史记录 */
    getMessageList(obj, firstLoad = false) {
      this.tim.getMessageList(obj).then((imResponse) => {
        let messageList = imResponse.data.messageList; // 消息列表。
        this.nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
        this.isCompleted =true
        setTimeout(() => {
         this.isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
        }, 1000);
        if (firstLoad) {
          this.messageList = messageList;
          //初始化聊天里面内容的高度
          this.getBoxHeight()
          /** 消息内容滚动到底部 */
          setTimeout(() => {
            this.replayBoxScrollBottom();
          }, 20);
        } else {
          this.messageList = [...messageList, ...this.messageList];
          this.goBoxHeight()
        }
        //消息设为已读
        this.massageRead();
      });
    },
    /** 获取更多历史消息 */
    getMoreMessageList() {
      const obj = {
        conversationID: "C2C" + this.userProfile.userID,
        nextReqMessageID: this.nextReqMessageID,
        count: 15,
      };
      this.getMessageList(obj);
    },
    /** 消息内容滚动到底部 */
    replayBoxScrollBottom() {
      this.$nextTick(() => {
        let scrollH = this.$refs.replayBox.scrollHeight
        this.$refs.replayBox.scrollTop = scrollH
      })
    },
    //监听到顶部加载更多
    replayBoxScroll() {
      if(this.$refs.replayBox.scrollTop<10&&!this.isCompleted){
        // this.getMoreMessageList()
      }
    },
    // 获取聊天内容里面的高度
    getBoxHeight(){
      this.boxHeight=this.$refs.replayBoxHeight.offsetHeight
    },
    // 跳转到指定位置高度
    goBoxHeight(){
      this.$nextTick(() => {
        // 获取相差高度
        let scrollTop=parseInt(this.$refs.replayBoxHeight.offsetHeight-this.boxHeight-this.$refs.replayBox.offsetHeight)
        this.$refs.replayBox.scrollTop = scrollTop
        this.boxHeight=this.$refs.replayBoxHeight.offsetHeight
      })
    },
    // 获取聊天消息列表
    getConversationList(){
      //获取会话列表
      this.tim.getConversationList().then(imResponse=>{
        this.chatList = imResponse.data.conversationList
        if(imResponse.data.conversationList.length>0){
          if(!this.userProfile.userID){
             this.userProfile=imResponse.data.conversationList[0].userProfile
          }
          //获取当前聊天历史
          this.getMessageList({ conversationID: "C2C" +  this.userProfile.userID}, true);
          //即时通讯获取当前聊天消息 只需要调用一次
          if(this.messageList.length<1){
           this.getMESSAGE_RECEIVED();
          }
        }


      }).catch(function(imError) {
        console.warn('getConversationList error:', imError); // 获取会话列表失败的相关信息
      });

    },
    // 点击聊天对象
    setUserID(userProfile){
      if(this.userProfile.userID==userProfile.userID){
        return false
      }
      // 关闭通讯监听
      this.deleteMESSAGE_RECEIVED()
      this.userProfile=userProfile
      //获取当前聊天历史
      this.getMessageList({ conversationID: "C2C" + userProfile.userID}, true);
      //即时通讯获取当前聊天消息
      // this.getMESSAGE_RECEIVED();
    },
  },
};
</script>