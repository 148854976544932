
<style lang="scss" scoped>
.write-tool{
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  .icon{
    font-size: 30px;
    height: 40px;
    height: 40px;
    color: #9D9D9D;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 15px;
    cursor: pointer;
  }
  .meme{
    width: 100%;
    height: 120px;
    position: absolute;
    left: 0;
    top: 30px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: #EDEFF6;
    .face-main{
      margin: 4px;

    }
    .emo-image{
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
  }
}
.ipt-box{
  margin: 14px 0;
  height: calc(100% - 100px);
  .textarea{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    color: #303030;
    caret-color: #6340c8;
    resize: none;
  }
}
.operate{
  text-align: right;
}
  .elUpload{
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
</style>

<template>
  <div class="message-write">
    <div class="write-tool">
      <!-- 表情 -->
      <div class="icon iconfont" @click="setShowMeme">&#xe658;</div>
      <!-- 图片 -->
      <label>
        <el-upload ref="uploadImage" action="233" accept="image/jpg,image/jpeg,image/png" class="elUpload" :auto-upload="false" :http-request="postPhoto" list-type="picture-card" :on-preview="handlePreview" :limit="1" :on-change="upImg"></el-upload>
        <div class="icon iconfont">&#xe659;</div>
      </label>
      <!-- 表情包 -->
      <div class="meme" v-if="showMeme">
        <div class="face-main" @click="select(index, item)" v-for="(item, index) in emojiName" :key="index">
          <img class="emo-image" :src="emojiUrl + emojiMap[item]" :title="item"/>
        </div>
      </div>
    </div>
    <div class="ipt-box">
      <textarea class="textarea" v-model="inputval" maxlength="1000" />
    </div>
    <div class="operate">
      <el-button type="custom_primary" size="medium" @click="submitInput">发送</el-button>
    </div>
  </div>
</template>

<script>
import { emojiUrl, emojiMap, emojiName } from "./emojiMap";
export default {
  name: "message-input",
  data() {
    return {
      //当前聊天内容
      inputval: "",
      //显示表情
      showMeme: false,
      //图片地址
      emojiUrl: "",
      //图片中文集合
      emojiName: [],
      //图片key对象
      emojiMap: {},
    };
  },
  created() {
    this.emojiUrl = emojiUrl;
    this.emojiName = emojiName;
    this.emojiMap = emojiMap;
  },
  watch: {
    value: {
      handler(newName, oldName) {
        this.inputval = newName;
      },
    },
  },
  methods: {
    // 设置是否显示表情
    setShowMeme() {
      this.showMeme = !this.showMeme;
    },
    //点击表情
    select(index, item) {
      this.inputval = this.inputval + item;
      this.showMeme=false
    },
    // 提交表单
    submitInput() {
      this.showMeme = false;
      this.$emit("submitInput", this.inputval);
      this.inputval = "";
    },
    //上传图片
    upImg(file) {
      this.$emit('upImg',{"res":file.raw})
      this.$refs.uploadImage.clearFiles()
    },
    postPhoto(){

    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>

